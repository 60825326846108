import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SectionTitle from "../components/sectionTitle"
import Section from "../components/section"
import QualiteContent from "../components/engagement/qualiteContent"
import RSEContent from "../components/engagement/rseContent"
import AntiConcurrenceContent from "../components/engagement/antiConcurrenceContent"

import engagementIcon from "../images/icon-engagement.png";
import QualityImage from "../images/qualite.jpg";
import AntiConcurrenceImage from "../images/zero-concurrence.jpg";
import AntiCorruptionImage from "../images/zero-corruption.jpg";
import SocialButtons from "../components/socialButtons"

const EngagementPage = () => (
  <Layout>
    <SocialButtons/>
    <SEO title="Cleeven s'engage"/>
    <div id="engagement-content" className="row">
      <Hero id="engagement" text="Certifications et chartes" />
      <SectionTitle text="Cleeven s'engage" iconImg={engagementIcon} />
      <Section id="politique-qualite" title="Politique Qualité" inversed whiteText imageUrl={QualityImage} bgColor="#408bc7">
        <QualiteContent/>
      </Section>
      <Section title="Engagements RSE" imageUrl={AntiCorruptionImage} bgColor="#ffffff">
        <RSEContent/>
      </Section>
      <Section title="Engagements RSE" inversed imageUrl={AntiConcurrenceImage} bgColor="#f3f3f3">
        <AntiConcurrenceContent/>
      </Section>
    </div>
  </Layout>
)

export default EngagementPage