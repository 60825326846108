import React from "react"

const AntiConcurrenceContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold pb-3 text-black">
      Charte anti-concurrence
    </h3>
    <h3 className="font-weight-bold pb-3 text-black">
      Tout collaborateur du groupe est concerné par la charte qui définit la politique anti-concurrentielle pratiquée
      chez Cleeven.
    </h3>
    <p className="text-blue font-weight-bold">Voici les principales pratiques proscrites au sein du Groupe :</p>
    <ul>
      <li>
        Ententes entre concurrents : Sur les prix, la répartition du marché, sur
        les échanges d’informations confidentielles, sur les différents boycotts...
      </li>
      <li>
        Ententes verticales : Chaque acteur doit demeurer libre de fixer ses
        propres tarifs.
      </li>
      <li>
        Accords de coopération avec des concurrents
      </li>
      <li>
        Abus de position dominante
      </li>
    </ul>
    <p className="font-italic">Cette liste n’est pas exhaustive.</p>

    <p>
      Outre les valeurs de Cleeven qui sont fondées sur l’intégrité, le courage, la réalisation et le devoir,
      l’entente concurrentielle est une pratique interdite dans la plupart des pays d'Europe, dont la France.
    </p>
    <p>
      En cas de doute sur une pratique engagée, il est impératif de se rapprocher du service des Ressources Humaines
      ou du service juridique de Cleeven.
    </p>
  </div>
)

export default AntiConcurrenceContent