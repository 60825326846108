import React from "react"

const QualiteContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold pb-3">
      Dans le cadre de sa certification à la norme ISO 9001, Cleeven a mis en place une démarche de qualité et d’amélioration continue.
    </h3>
    <ul>
      <li>
        Les axes stratégiques de la politique qualité sont déclinés en indicateurs et objectifs de performance dans les processus contributeurs.
      </li>
      <li>
        Ils sont récapitulés dans un tableau de bord par entité qui définit la périodicité de calcul des indicateurs, leur source, leur mode et méthode de calcul, les résultats et les commentaires associés.
      </li>
      <li>
        Ces tableaux de bord sont complétés, suivis et analysés conjointement par les référents qualité et les pilotes de processus.
      </li>
      <li>
        Ils sont disponibles sur Google Drive.
      </li>
    </ul>
    <a target="_blank" className="text-white font-weight-bold" href="/politique_qualite_cleeven.pdf">
      &gt;&gt; Télécharger la Politique Qualité de Cleeven
    </a>
  </div>
)

export default QualiteContent