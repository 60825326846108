import React from "react"

const RSEContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold pb-3 text-black">
      Cleeven s’engage dans une démarche RSE !
    </h3>
    <p>
      La responsabilité sociétale des entreprises est aujourd’hui un pilier important chez Cleeven et s’inscrit
      pleinement dans notre mission, qui est de contribuer à augmenter le degré de conscience de soi et de ceux qui
      nous entourent.
    </p>
    <h3 className="font-weight-bold pb-3 text-black">
      Charte anti-corruption
    </h3>
    <h3 className="font-weight-bold pb-3 text-black">
      La charte définit la politique anti-corruption de Cleeven, qui s’engage à appliquer une politique de tolérance zéro à propos de toute forme de corruption.
    </h3>
    <ul>
      <li>
        Les valeurs de Cleeven étant l’intégrité, le courage, la réalisation et le devoir, nous mettons tout en oeuvre pour garantir des pratiques anti-corruption.
      </li>
      <li>
        La corruption est définie en droit français comme “un comportement pénalement répréhensible par lequel une personne (le corrompu) sollicite, agrée ou accepte un don, une offre ou une promesse, des présents ou des avantages quelconques en vue d’accomplir, de retarder ou d’omettre d’accomplir un acte entrant d’une façon directe ou indirecte dans le cadre de ses fonctions.”
      </li>
      <li>
        Nous rappelons à nos collaborateurs que toute pratique de corruption est sanctionnée par la loi Sapin 2.
      </li>
    </ul>
  </div>
)

export default RSEContent